import React from "react";
import Layout from "../components/layout";

const Rolunk = () => (
  <Layout>
    <div className="container content-wrap">
      <div className="row my-3">
        <div className="col">
          <h1>Rólunk</h1>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div>
            {/* <img
              src="../images/about/anna_juli.jpg"
              className="img-fluid rounded float-left wrap-image-on-left mb-3 mr-3"
              alt="Rólunk"
            /> */}
            <p className="text-justify my-3">
              Történetünk valamikor az 50-es években kezdődött, amikor{" "}
              <strong>nagyszüleim a Pauler utca 8-ba</strong> költöztek. Akkor
              kezdte el élni családunk ebben a házban az életét. Aztán apukám és
              anyukám is itt indították közös életüket, ide születtünk
              testvéreimmel. Apukám minőségi kézműves bútorokkal foglalkozott
              (még most is), anyukám pedig minőségi kézműves textilekkel, így
              indult el a mostani Szelencében közös üzletük, amelynek nyomait ma
              is láthatjátok. Minden bútordarabunk vagy textilünk az ő kezük
              nyomát őrzi.
            </p>
            <p className="text-justify my-3">
              Papa mindig vendéglátással akart foglalkozni, egész
              gyerekkorunkban azt hallgattuk, hogy mikor csinálunk panziót vagy
              éttermet. A gondolat tőlem sem állt messze, hiszen kislány
              koromtól kezdve imádtam sütni, és titkon cukrásznak készültem.
              Mivel anyukám főztje is egyedülállóan ötletes volt, a 90-es
              években minden osztálytársunk nálunk ebédelt… így az alapok már
              megvoltak. Tapasztalatot gyűjtöttünk, tanultunk, majd álmodtunk
              egy nagyot, és régi lakásunkban kialakítottuk a Szelence Cafét,
              ahol kis családi vállalkozásként működünk. Az étterem mellett
              igyekszünk <strong>interaktív közösségi</strong> térként is
              működni, hiszen rengeteg programnak adunk helyet. Például
              szülinapi zsúroknak, tanfolyamoknak, kézműves workshopoknak és
              családi vagy babaprogramoknak, jó hangulatú üzleti találkozóknak.
              Szeretnénk, ha látogatóink otthon éreznék magukat, ezért bátran
              lehet <strong>babával vagy kutyussal</strong> is jönni hozzánk.
            </p>
            <p className="text-justify my-3">
              Étlapunkat igyekeztünk úgy kialakítani, hogy olyan vendégek is
              tudjanak fogyasztani nálunk, akik
              <strong>laktóz-, gluténérzékenyek</strong>, vagy paleo, esetleg
              vegán életmódot élnek. Ételeink mind ízletes{" "}
              <strong>vegetáriánus</strong>, reform ételek, amelyek legtöbbjét
              édesanyám kísérletezte ki és főzi minden nap. A vegetáriánus és
              vegán menüben előfordulnak egészen újszerű, ínyenc reform ízek, és
              vannak olyan napok, ahol a hagyományos ételek a nagymamám főztjét
              idézik. Népszerű desszertjeink általunk kikísérletezett{" "}
              <strong>házilag készített</strong>, finom nyers vegán sütemények,
              amelyekhez sem lisztet, sem tejet vagy cukrot nem használunk.
            </p>
            <p className="text-justify my-3">
              Amire büszkék vagyunk, hogy legtöbb vendégünk úgy érkezik hozzánk,{" "}
              <strong>mintha hazajönne</strong>. Igyekszünk otthonos, családias
              hangulattal várni az ide érkezőket. Szívesen látjuk a vegetáriánus
              és a vegán, az ételérzékeny és a hagyományos ízlésű látogatóinkat
              is, a babás kismamákat, a kutyabarátokat, a biciklivel érkezőket,
              a finom és egészséges munkanapi ebédre vágyókat. Sőt, a vegyes
              ízlésű barátokat, társaságokat is, hiszen sokan éppen azért
              szeretnek járni hozzánk, mert a mi kínálatunkban nem egyetlen
              irányzatból lehet választani. Várunk mielőbb Téged is egy
              Szelencés élményre!
            </p>
            <p className="my-5">
              Szeretettel: a Vető család (Panni, Juli, Évi, Imre)
            </p>
          </div>
        </div>
      </div>
      {/* <div className="row">
        <h2>Filozófiánk</h2>
        <table className="table table-responsive">
          <tbody>
            <tr>
              <th>Családias vendéglátás</th>
              <th>Hagyománytisztelet</th>
              <th>Egészségtudatosság</th>
              <th>Kuriózum</th>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Mi</strong> magunk fogadjuk a vendégeket, ami igazán
                  otthonossá teszi a helyet, hiszen olyan, mintha otthon látnánk
                  vendégül
                </p>
                <p>
                  <strong>Sajátkezűleg</strong> készülnek az ételeink válogatott
                  termelőktől származó alapanyagokból
                </p>
                <p>
                  <strong>Nemcsak</strong> a gyermekek, hanem a tágabb
                  családtagok, a kutyák is szívesen látott vendégek – kutyabarát
                  café.
                </p>
                <p>
                  <strong>A családi</strong> vállalkozás tagjaitól származik az
                  ételen kívül is szinte minden: a berendezés bútorai, textilei.
                </p>
                <p>
                  <strong>A Szelence</strong> hangulata és berendezése, stílusa
                  is otthonos.
                </p>
              </td>
              <td>
                <p>
                  <strong>A mi</strong> Szelencénk a hagyományokra épít, amit az
                  Eger melletti kis faluból származó nagymamánktól és a világ
                  konyhája felé nyitott édesanyánktól örököltünk.
                </p>
                <p>
                  <strong>Tiszteljük</strong> a természetet: természetes
                  alapanyagokat alkalmazunk az ételek készítése, a csomagolás, a
                  berendezés során.
                </p>
                <p>
                  <strong>A Café</strong> stílusában is jelentős szerepet kap a
                  kedves régi holmik felújítása, a pozitív nosztalgia, a vintage
                  hangulat.
                </p>
              </td>
              <td>
                <p>
                  <strong>Tudatosak vagyunk</strong> az alapanyagok, hozzávalók
                  tisztasága terén.
                </p>
                <p>
                  <strong>A zöldségalapú</strong>, vegetáriánus és nyers vegán
                  ételekkel minél teljesebb értékű táplálkozási formát nyújtunk
                  vendégeinknek
                </p>
                <p>
                  <strong>A tudatos</strong> táplálkozás otthonos oázisaként
                  segítséget nyújtunk az új irányokat keresőknek és egészséges,
                  elérhető alternatívát a konzervatív táplálkozóknak.
                </p>
              </td>
              <td>
                <p>
                  <strong>Egészséges</strong>, mégis gourmand igényeket is
                  kielégítő, különleges ízekkel várunk -kész gasztrokaland.
                </p>
                <p>
                  <strong>Szolgáltatások</strong> egyedi kombinációját kínáljuk,
                  hiszen a családi vállalkozásban nemcsak éttermi szolgáltatás,
                  hanem színvonalas felnőtt- és gyermekprogramok, zsúrok is
                  elérhetőek.
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div> */}
    </div>
  </Layout>
);

export const Head = () => <title>Szelence Café | Rólunk</title>;

export default Rolunk;
